<template>
  <!-- ======= pie ======= -->
  <footer id="footer">

<div class="footer-top">
  <div class="container">
    <div class="row">

      <div class="col-lg-4 footer-image d-flex  justify-content-center">
        <img src="@/assets/img/logo_uniovi_pie.png" class="img-fluid" alt="Universidad de oviedo" >
      </div>

      <div class="col-lg-5 col-sm-7 footer-contact">
        <h3>Universidad de Oviedo</h3>
        <p>
          Departamento y el Área de Contemporánea Facultad de Filosofía y Letras <br>
          Departamento de Historia (Área de Historia Contemporánea)<br> <br>
          <strong>Teléfono:</strong><a href="tel:+34985104437"> 985 104 437</a><br>
          <strong>Email:</strong> <a href="mailto:carmeng@uniovi.es"> carmeng@uniovi.es</a><br>
        </p>
      </div>    

      <div class="col-lg-3 col-sm-5 footer-links">
        <h4>Menú navegación</h4>
        <ul>
          <li><i class="fas fa-angle-right"></i> <router-link to="/">Inicio</router-link></li>
          <li><i class="fas fa-angle-right"></i> <router-link to="/mapa-fosas">Mapa de Fosas</router-link></li>
          <li><i class="fas fa-angle-right"></i> <router-link to="/contacto">Contacto</router-link></li>             
        </ul>
      </div>       

    </div>
  </div>
</div>

<div class="container d-md-flex py-4">

  <div class="me-md-auto text-center text-md-start">
  <!-- -cp- -->
  </div>
  <div class="Aviso text-center text-md-right pt-3 pt-md-0">
    <a href="https://www.uniovi.es/pol%C3%ADtica-de-privacidad" target="_blank">Pólitica de privacidad</a> I
    <a href="https://www.uniovi.es/aviso-legal" target="_blank">Aviso legal</a>    
  </div>
</div>
</footer><!-- fin del pie -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>  
</template>